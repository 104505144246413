'use client';

import classNames from 'classnames';

import Button, {
  convertLegacyColorToNewColor,
} from '@/app/components/base/ui/Button';
import useStore from '@/store';

const MemorizedDataForm = ({
  offerId,
  coupon,
  label = ' Escolha suas maquininhas',
  onClick,
  color,
  fullWidth,
  sectionReference,
  plan,
}: {
  offerId: string;
  coupon?: string;
  plan?: string;
  onClick?: () => void;
  sectionReference?: string;
  label?: string;
  color?: 'primary' | 'stonePrimary' | 'secondary' | 'ghost';
  fullWidth?: boolean;
}) => {
  const store = useStore();

  const formData = {
    'user.name': store.user.full_name,
    'user.email': store.user.email,
    'user.phone': store.user.phone,
    offerId: offerId,
    'utm.source': store.utm_source,
    'utm.medium': store.utm_medium,
    'utm.campaign': store.utm_campaign,
    'utm.term': store.utm_term,
  };

  return store.user.full_name ? (
    <form
      method="post"
      action={`${process.env.NEW_AUTOCRED_BASE_URL}/api/session`}
    >
      <input name="redirectTo" type="hidden" value="/dados" />
      <input name="user.name" value={formData['user.name']} type="hidden" />
      <input name="user.email" value={formData['user.email']} type="hidden" />
      <input name="user.phone" value={formData['user.phone']} type="hidden" />
      <input name="offerId" value={formData['offerId']} type="hidden" />
      <input
        name="coupon"
        defaultValue={coupon?.replace('&coupon=', '')}
        type="hidden"
      />
      <input
        name="utm.source"
        defaultValue={formData['utm.source']}
        type="hidden"
      />
      <input
        name="utm.medium"
        defaultValue={formData['utm.medium']}
        type="hidden"
      />
      <input
        name="utm.campaign"
        defaultValue={formData['utm.campaign']}
        type="hidden"
      />
      <input
        name="utm.term"
        defaultValue={formData['utm.term']}
        type="hidden"
      />
      <Button
        className={classNames(
          {
            '!w-full': fullWidth,
          },
          'min-h-[56px]',
        )}
        plan={plan}
        onClick={onClick}
        type="submit"
        data-testid="submit-form"
        sectionReference={
          sectionReference
            ? sectionReference
            : 'Formulário Escolha suas maquininhas'
        }
      >
        {label}
      </Button>
    </form>
  ) : (
    <Button
      linkHref={`${process.env.NEW_AUTOCRED_BASE_URL}/dados`}
      linkQuery={`offer_id=${offerId}${coupon}`}
      plan={plan}
      className={classNames(
        {
          '!w-full': fullWidth,
        },
        'min-h-[56px]',
      )}
      onClick={onClick}
      type="submit"
      data-testid="submit"
      color={convertLegacyColorToNewColor(color)}
      sectionReference={
        sectionReference
          ? sectionReference
          : 'Formulário Escolha suas maquininhas'
      }
    >
      {label}
    </Button>
  );
};

export default MemorizedDataForm;

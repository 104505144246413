'use client';

import Link from 'next/link';
import React, { useContext } from 'react';

import { AmplitudeContext } from '@/contexts/amplitude';

import styles from '../WhatsappButtonTestAB/styles.module.css';

const FloatingButtonAdquira = () => {
  const { analytics } = useContext(AmplitudeContext);

  return (
    <Link
      href="/adquira"
      prefetch={false}
      className={`fixed right-24 bottom-24 z-[50] font-sharon font-semibold btn btn-primary btn-large ${styles.animateShake}`}
      onClick={() => {
        analytics?.track({
          event_type: 'cta stone',
          event_properties: {
            name: 'cta stone',
            description:
              'Evento disparado quando o usuário clicar no botão de "Adquira agora" que fica no canto inferior direito da página',
            section_reference: 'canto inferior direito | página toda',
          },
        });
      }}
    >
      Adquira agora
    </Link>
  );
};

export default FloatingButtonAdquira;

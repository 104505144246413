'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { useState } from 'react';

import Icon from '@/components/v3/Icon';
import { FeatureCardIconProps, ImageCMSProps } from '@/types/cms';

import { AccountFeedbackStates, Feedback } from './Feedback';
import Form from './form';

interface OpenAccountProps {
  referId: string;
  mobileTitle: string;
  imageBanner: ImageCMSProps;
  cards: FeatureCardIconProps[];
}

export function OpenAccount({
  referId,
  mobileTitle,
  imageBanner,
  cards,
}: OpenAccountProps) {
  const [status, setStatus] = useState('' as AccountFeedbackStates);

  return (
    <section
      id={referId}
      className="flex justify-center items-center md:mr-24 lg:mr-0 bg-display-100 md:bg-display-0"
    >
      <div className="flex flex-col-reverse md:flex-row justify-center items-stretch w-full max-w-lg md:max-w-screen-xl h-auto md:min-h-screen">
        <div
          className={classNames(
            'flex p-24 lg:px-80 w-full min-w-full md:min-w-[384px] lg:min-w-[608px] md:max-w-3xl bg-display-0 ',
            {
              'rounded-t-2xl': status !== 'account_openning_under_review',
            },
          )}
        >
          {status ? (
            <Feedback status={status} setStatus={setStatus} />
          ) : (
            <Form setStatus={setStatus} />
          )}
        </div>
        <div className="flex md:overflow-hidden relative justify-center mt-[68px] md:mt-0 w-full h-full md:min-h-screen lg:rounded-r-5xl">
          <div
            className={classNames(
              'grid md:absolute md:right-[16px] lg:right-[32px] md:bottom-[16px] lg:bottom-[32px] md:left-[16px] lg:left-[32px] md:z-[1] grid-cols-1 xl:grid-cols-2 gap-8 md:gap-24 md:p-32 py-16 px-24 md:mx-auto max-w-max md:bg-[#6A6A6A80] md:bg-opacity-50 ld:rounded-5xl md:rounded-2xl md:border md:border-white',
              {
                'hidden md:grid': status === 'account_openning_under_review',
              },
            )}
            style={{ backdropFilter: 'blur(50px)' }}
          >
            <Image
              src="/site-stone/blur"
              width={161}
              height={9}
              alt="Ellipse"
              className="absolute top-[-4px] left-[62px] z-[2] w-[161px] h-[9px]"
            />

            <h3
              className="md:hidden mb-8 paragraph-16"
              dangerouslySetInnerHTML={{ __html: mobileTitle }}
            />

            {cards.map((card, index) => (
              <div
                className="flex flex-row gap-8 md:gap-16 items-center w-full"
                key={index}
              >
                <div className="flex justify-center items-center md:min-w-[40px] md:h-[30px] md:bg-[#BCC8D633] bg-opacity-20 rounded-[8px]">
                  <Icon
                    name={card.iconName}
                    className="text-stone-500 md:text-display-0 fill-current"
                  />
                </div>
                <div className="flex flex-col">
                  <h2
                    className="!font-normal text-display-900 md:text-display-0 paragraph-16 md:heading-5"
                    dangerouslySetInnerHTML={{ __html: card.title || '' }}
                  />
                  <p
                    className="hidden md:block text-display-0 paragraph-16"
                    dangerouslySetInnerHTML={{
                      __html: card.description || '',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <Image
            src={imageBanner.path}
            alt="Banner Stone"
            width={672}
            height={672}
            className="hidden md:flex object-cover object-top min-h-screen"
          />
        </div>
      </div>
    </section>
  );
}

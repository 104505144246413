export const statesOptions = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

export const businessStatesOptionsList = [
  { label: 'Alagoas', value: 'AL' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  {
    label: 'Não encontrei o estado desejado!',
    value: 'XX',
  },
];

export const businessCitiesOptionsList = {
  AL: ['Arapiraca'],
  BA: ['Santo Amaro'],
  CE: ['Itaitinga'],
  ES: ['Aracruz', 'Baixo Guandu', 'Colatina', 'Santa Maria de Jetibá'],
  GO: ['Goianira'],
  MA: ['Imperatriz'],
  MG: [
    'Almenara',
    'Barbacena',
    'Cataguases',
    'Guanhães',
    'Itajubá',
    'Manhuaçu',
    'São João Del Rei',
    'Santa Rita do Sapucaí',
    'Ubá',
  ],
  PR: ['Cornélio Procópio', 'Guarapuava', 'Palmas'],
  PI: ['São Raimundo Nonato'],
  RJ: ['Itaperuna', 'Santo Antônio de Pádua'],
  RS: ['Cachoeira do Sul'],
  RO: ['Pimenta Bueno'],
  SC: [
    'Caçador',
    'Concórdia',
    'Ituporanga',
    'Joaçaba',
    'Mafra',
    'Rio do Sul',
    'São Bento do Sul',
    'Videira',
  ],
  SP: ['Araçatuba', 'Ituverava', 'Penápolis'],
  SE: ['Itabaiana', 'Lagarto', 'Nossa Senhora da Glória'],
  XX: ['Não encontrei a cidade desejada!'],
};
